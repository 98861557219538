<template>
    <page title="Ankiety - Wczytaj CSV">
        <div class="row">
            <div class="col col-md-6 col-sm-12">
                <input-field type="file"
                             label="plik"
                             name="file"
                             @change="onFileChange"
                             :value="this.file ? this.file.filename : ''"
                             :key="key"
                >
                </input-field>
            </div>
        </div>
        <div class="row">
            <div class="col col-md-6 col-sm-12 text-end">
                <b-btn variant="success" :disabled="isBusy || !file" @click="sendFile">Wyślij<span v-show="isBusy" class="fa fa-spin fa-spinner ms-1"></span></b-btn>
            </div>
        </div>
    </page>
</template>

<script>
import page from "@/pages/Common/Page";
import axios from 'axios';
import InputField from "@/components/Forms/InputField.vue";
import Vue from "vue";
export default {
    name: "UploadPage",
    components: {
        page,
        InputField
    },
    data() {
        return {
            file: null,
            isBusy: false,
            key: 0,
        }
    },
    methods: {
        onFileChange(e) {
            console.log('event', e);
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file = files[0];
            this.filename = this.file.filename
        },
        sendFile() {
            if (this.file && !this.isBusy) {
                this.isBusy = true;
                let formData = new FormData();
                formData.append('file', this.file);
                axios.post('polls/upload_csv', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                } ).then(response => {
                    if (response.data.status !== 'OK') {
                        Vue.notify({
                            group: 'main',
                            type: 'error',
                            title: 'Błąd podczas wczytywania pliku CSV'
                        });
                    } else {
                        this.file = null;
                        this.key++;
                        let rowCount = response.data.row_count;
                        Vue.notify({
                            group: 'main',
                            type: 'success',
                            title: 'Plik CSV został poprawnie wczytany',
                            text: 'Dodano rekordów: ' + rowCount + '.'
                        });
                    }
                }).finally(() => {
                    this.isBusy = false;
                })
            }
            console.log(this.file)
        }
    }
}
</script>

<style scoped>

</style>